/* ==========================================================================
Variables
========================================================================== */
// TYPE
$font--primary:          'Times New Roman','Times', serif;

$font-xl: rem(100);
$font-l: rem(38);
$font-m: rem(30);
$font-s: rem(20);
$font-xs: rem(14);

$font-xl--mobile: rem(60);
$font-l--mobile: rem(36);
$font-m--mobile: rem(28);
$font-s--mobile: rem(18);
$font-xs--mobile: rem(11);

// COLOR
$color--dark:            #1e1e1e;
$color--darker:            #020202;
$color--light:           #f3f1f1;
$color--lighter:           #ffffff;
$color--accent:          rgb(143, 179, 139);

// LAYOUT
$breakpoints: (
  'small':          (min-width: 400px),
  'medium':          (min-width: 800px),
  'large':           (min-width: 1000px),
  'huge':            (min-width: 1200px),
);

$pageWidth: rem(600);