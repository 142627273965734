/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Page home
~~~~~~~~~~~~~~~~~~~~~~~~~ */
.siteMain {
	width: 100%;
	max-width: $pageWidth;
	margin: 0 auto;
	padding: 0;
}
.box {
	background-color: $color--lighter;
	padding: 1rem;
	margin: 0 0 1rem 0;
	aspect-ratio: 5/3;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
	@include respond-to('medium') {
		padding: 2rem;
	}
	&.insta {
		position: relative;
		padding: 2rem;
		img {
			position: absolute;
			left: 1rem;
			top: 1rem;
			z-index: 0;
		}
		h2,a {
			z-index: 1;
			color: $color--lighter;
		}
		.overlay {
			border-color: $color--lighter;
			background: transparent;
		}
	}
}
.intro {
	font-style: italic;
	text-align: center;
	justify-content: center;
	background-color: rgba($color--accent,0.5);
	h2 {
		max-width: 26ch;
		margin: 0 auto;
		font-size: $font-m--mobile;
		@include respond-to('medium') {
			font-size: $font-l;
		}
	}
}

// Carta
.cart-layout {
	background-color: $color--lighter;
	padding: 2rem 1rem;
	align-items: stretch;
	justify-content: flex-start;
	@include respond-to('medium') {
		padding: 2rem;
	}
	&.wines {
		background-color: $color--dark;
		color: $color--light;
	}
}
.page-intro {
	padding: 2rem 0;
	text-align: center;
	@include respond-to('medium') {
		text-align: left;
	}
}

.carta,
.kitchen-stories {
	ul {
		margin: 0 0 2rem 0;
		padding: 0;
		width: calc(100% - 2rem);
		@include respond-to('medium') {
			padding: 0 2rem 0 1.5rem;
		}
		
		li {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			width: 100%;
			margin: 0 0 1rem 0;
			padding: 0;
			.description {
				max-width: 32ch;
				@include respond-to('medium') {
					max-width: 43ch;
				}
			}
			strong {
				display: flex;
				flex-grow: 1;
				margin-left: 1rem;
				&:before {
					background: repeating-linear-gradient(to right, currentColor, currentColor 1px, transparent 2px, transparent 4px);
					content: '';
					display: inline-block;
					flex-grow: 1;
					height: 1px;
					margin: 1.1rem 0.25rem 0 0.25rem;
					@include respond-to('medium') {
						height: 2px;
						margin: 1.1rem 0.5rem 0 0.5rem;
					}
				}
			}
		}
	}
}