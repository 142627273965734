/*  ==========================================================================
    General
    ========================================================================== */
    html {height: 100vh;}
    body {
      background-color: $color--light;
      color: $color--dark;
      display: grid;
          grid-template-columns: 1fr;
          grid-auto-rows: 1fr auto 1fr;
      min-height: 100vh;
      font-family: $font--primary;
      font-size: $font-s;
      margin: 0;
      padding: 0 1rem;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialised;
      -webkit-text-stroke: 1px transparent;
    }

/*  ==========================================================================
    Images
    ========================================================================== */

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }


/*  ==========================================================================
    Elements
    ========================================================================== */

	address { margin: 0 0 1.5rem; }
    

    a {
	  color: $color--dark;
      text-decoration: underline;
      text-decoration-color: $color--accent;
      display: inline-block;
	  // &:visited { color: transparent; }
      &:hover {
	    text-decoration: none;
	    color: $color--dark;
        }
    }
    
    .button {
        -webkit-appearance: none;
        background-color: $color--lighter;
        border: 2px solid $color--accent;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-weight: 300;
        color: $color--accent;
        display: inline-block;
        &:hover {
            border-color: $color--dark;
        }
    }
