/*	==========================================================================
  	Typography
  	========================================================================== */

		h1 {
			font-size: $font-xl--mobile;
			font-style: italic;
			font-weight: 400;
			line-height: 1;
			margin: 0;
			padding: 0;
			@include respond-to('medium') {
				font-size: $font-xl;
			}
		}

		h2 {
			font-size: $font-l--mobile;
			font-style: italic;
			font-weight: 400;
			line-height: 1.2;
			margin: 0 0 0.4rem 0;
			padding: 0;
			@include respond-to('medium') {
				font-size: $font-l;
			}
		}
		h3 {
			font-size: $font-m--mobile;
			font-weight: 400;
			line-height: 1.2;
			margin: 0;
			padding: 0;
			@include respond-to('medium') {
				font-size: $font-m;
			}
		}


		p,span,li {
			font-size: $font-s--mobile;
			line-height: 1.4;
			margin: 0 0 1.2rem 0;
			padding: 0;
			ul {
				padding: 0;
				line-height: 1.2;
				font-size: $font-s--mobile;
			}
			@include respond-to('medium') {
				font-size: $font-s;
				ul {
					font-size: $font-s;
				}
			}
		}
		span,li {
			font-size: $font-s--mobile;
			line-height: 1.4;
			margin: 0;
			padding: 0;
			@include respond-to('medium') {
				font-size: $font-s;
			}
		}

		b,
		strong { font-weight: 700; }

		small {
			font-size: $font-xs;
			line-height: 1.2;
		}
