@media print {
	body {
		background-color: $color--lighter;
		margin: 0;
		padding: 0 0.5rem;
		min-height: auto;
		height: auto;
	}
	span,li {
		font-size: 16px;
	}
	h2 {
		font-size: $font-m--mobile;
	}
   .siteMenu,
   .siteFooter,
   .page-intro {
	   display: none;
   }
   
   .siteHeader {
	   justify-content: flex-end;
	   position: relative;
	   padding: 0;
	   margin: 0;
	   .brandLogo {
		   padding: 0.5rem 0 0 0;
		   #debut-logo {
			   max-width: rem(100); }
		   #bar-logo {
			   max-width: calc(100px/3); }
	   }
   }
   .siteMain {
	   max-width: none;
   }
   .kitchen-stories {
	   padding: 0;
	   
	   .cart-layout {
		   padding: 0;
		   width: 100%;
		   align-items: flex-start;
		   justify-content: flex-start;
		   &.box {
			   aspect-ratio: auto;
		   }
	   }
	   ul {
		   padding: 0 0 0 1rem;
		   width: calc(100% - 1rem);
		   
		   li {
			   margin: 0 0 0.5rem 0;
			   padding: 0;
			   .description {
				   max-width: none;
			   }
			   strong {
				   display: flex;
				   flex-grow: 1;
				   margin-left: 1rem;
				   &:before {
					   height: 1px;
					   margin: 0.95rem 0.5rem 0 0.15rem;
				   }
			   }
		   }
	   }
   }
}