.subscribe {	
	.form--subscribe {
	}
}

#mc_embed_signup {
	max-width: rem(600);
	.form-items {
		margin-bottom: .5rem;
	}
	.formGroup {
		display: flex;
		flex-flow: column wrap;
		position: relative;
	}
	.form-label {
		font-size: rem(15);
		padding: .25rem 0;
	}
	.form-input {
		background-color: #fff;
		border: 1px solid $color--dark;
		font-size: 1rem;
		padding: 1rem;
		margin: 0 0 1rem 0;
		appearance: none;
		color: $color--dark;
		&.mce_inline_error {
			border-color:#ff6e42;
		}
	}
	
	.mc-field-group {
		width: 100%;
		position: relative;
		div.mce_inline_error {
			background-color: transparent;
			font-size: rem(14);
			padding: 0;
			text-align: left;
			z-index: 1;
			color:#ff6e42;
		}
	}
	.referal {
		padding: 2em 0 0 0;
		color: rgba($color--dark, .5);
		display: inline-block;
		a {
			color: inherit;
		}
	}
}
