/* ==========================================================================
   Footer
   ========================================================================== */
	 .siteFooter {
		 margin: 3rem 0 0 0;
		 padding: 0;
		 @include respond-to('large') {
		 	position: fixed;
			bottom: 0;
			padding: 0 1rem;
		 }
		 p,a {
			 font-size: $font-xs;
		 
		 }
		 .disclaimer {
			 font-size: $font-xs;
		 }
	 }