.siteHeader {
	width: 100%;
	margin: 0;
	padding: 1rem 0 0 0;
	text-align: center;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;	
	@include respond-to('large') {
		align-items: flex-start;	
		flex-flow: row wrap;
		position: fixed;
		justify-content: space-between;
		padding: 1rem 0;
		
	}
}
.brandLogo {
	order: 1;
	padding: 0;
	@include respond-to('large') {
		order: 2;
		padding: 0 3rem;
	}
	&-link {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-end;
	}
	#debut-logo {
			fill: $color--dark;
			width: rem(186);
			display: block;
			background-color: $color--light;
			z-index: 1;
	}
	#bar-logo {
			fill: $color--dark;
			width: calc(186px/3);
			display: block;
			margin-top: -3px;
			background-color: $color--lighter;
			z-index: 0;
			animation: barMobile 10s forwards infinite;
			@include respond-to('large') {
				animation: bar 10s forwards infinite;	
			}
	}
}

.siteMenu {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	gap: 2rem;
	padding: 1rem 0 0 0;
	order: 2;
	@include respond-to('large') {
		justify-content: flex-start;
		order: 1;
		padding: 0 1rem;
	}	

	a {
		font-size: $font-s;
		text-decoration: none;
		color: $color--dark;
		&:hover {
			text-decoration: underline;
		}
	}
}

@keyframes bar {
  0% {
	transform: translateY(0);
  }
  50% {
	transform: translateY(-3rem);
  }
  100% {
	transform: translateY(0);
  }
}

@keyframes barMobile {
  0% {
	transform: translateX(0);
  }
  50% {
	transform: translateX(-190%);
  }
  100% {
	transform: translateX(0);
  }
}