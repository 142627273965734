/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Cards
~~~~~~~~~~~~~~~~~~~~~~~~~ */

.xmas {
	background-color: rgb(202, 82, 90);
	margin: 1rem 0 3rem 0;

--mask:
		radial-gradient(9.33px at 50% 12.80px,#000 99%,#0000 101%) calc(50% - 8px) 0/16px 51% repeat-x,
		radial-gradient(9.33px at 50% -4.4px,#0000 99%,#000 101%) 50% 4px/16px calc(51% - 4px) repeat-x,
		radial-gradient(9.33px at 50% calc(100% - 12.80px),#000 99%,#0000 101%) calc(50% - 8px) 100%/16px 51% repeat-x,
		radial-gradient(9.33px at 50% calc(100% + 4.80px),#0000 99%,#000 101%) 50% calc(100% - 4px)/16px calc(51% - 4px) repeat-x;
	  -webkit-mask: var(--mask);
			  mask: var(--mask);
}